import React from "react"
import Spinner from "./Spinner"

const KnockOut = () => {
  return (
    <div className="knockout">
      <Spinner />
    </div>
  )
}

export default KnockOut
