import React, { Fragment } from "react"
import { Link } from "gatsby"

// Components
import Logo from "./Logo"
import HeroActions from "./HeroActions"

const SiteHeader = () => {
  return (
    <Fragment>
      <a className="[ skip-link ] [ button ]" href="#main-content">
        Skip to content
      </a>
      <header role="banner" className="site-header">
        <div className="wrapper">
          <div className="[ site-header__inner ] [ mirror ]">
            <Link
              to="/"
              aria-label="Pod Please - Home"
              className="site-header__brand"
            >
              <Logo />
            </Link>
            <HeroActions isSiteHeader />
          </div>
        </div>
      </header>
    </Fragment>
  )
}

export default SiteHeader
