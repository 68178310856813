import React, { createContext, useState, useEffect } from "react"
import { useQuery } from "react-query"

import { Pods } from "../config/db"
import withWindow from "../helpers/withWindow"
import firebase from "gatsby-plugin-firebase"

export const AuthContext = createContext()

export const AuthContextProvider = ({ children }) => {
  const [isAuth, setIsAuth] = useState("Loading...")
  const [user, setUser] = useState()
  const { data: hasPod, isLoading, refetch } = useQuery(
    "AuthContext - hasPod",
    async () => {
      const phoneNumber = firebase.auth().currentUser?.phoneNumber
      if (!phoneNumber) return
      return await Pods.fetchPod("phoneNumber", phoneNumber)
    },
    { retry: false, refetchOnWindowFocus: false }
  )

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setIsAuth(true)
        setUser(user)
        refetch()
      } else {
        setIsAuth(false)
        setUser(null)
      }
    })
  }, [refetch])

  return (
    <AuthContext.Provider value={{ user, isAuth, hasPod, isLoading, refetch }}>
      {children}
    </AuthContext.Provider>
  )
}

export default withWindow(AuthContextProvider)
