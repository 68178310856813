import React, { useContext } from "react"
import { Link } from "gatsby"

// Components
import { AuthContext } from "../../context/AuthContext"
import withWindow from "../../helpers/withWindow"

const HeroActions = ({ isSiteHeader }) => {
  const { isLoading, hasPod, isAuth } = useContext(AuthContext)

  return (
    <div className="[ hero__actions ] [ flow-hrz flow-space-400 ]">
      <Link to="/find" className="button">
        Find a pod
      </Link>
      {isLoading ? (
        <button disabled className="button green">
          Loading...
        </button>
      ) : hasPod ? (
        <Link to={`/profile?id=${hasPod.id}`} className="button green">
          Go to my pod
        </Link>
      ) : (
        <Link
          to={isAuth ? "/create" : "/signin?mode=create"}
          className="button green"
        >
          Create a pod
        </Link>
      )}
      {isSiteHeader && !isAuth && (
        <Link to="/signin?mode=manage" className="button" data-variant="ghost">
          Manage my pod
        </Link>
      )}
    </div>
  )
}

export default withWindow(HeroActions)
