import firebase from "gatsby-plugin-firebase"

let instance

export default function db() {
  if (typeof window !== "undefined") {
    if (instance) return instance
    instance = firebase.database()
    return instance
  }
}

export const Pods = {
  list: () =>
    db()
      .ref("pods")
      .once("value")
      .then(pods => pods.val())
      .then(pods =>
        pods ? Object.entries(pods).map(pod => ({ ...pod[1], id: pod[0] })) : []
      )
      .catch(error => console.error(error)),
  fetchPod: (key, value) =>
    Pods.list()
      .then(pods => pods.find(pod => value === pod[key]))
      .catch(error => console.error(error)),
  fetchById: id =>
    db()
      .ref("pods")
      .child(id)
      .once("value")
      .then(pod => {
        if (!pod.val()) throw new Error("No pod found")
        return { ...pod.val(), id: pod.key }
      })
      .catch(error => console.error(error)),
  create: data =>
    db()
      .ref("pods")
      .push(data)
      .then(podId => podId)
      .catch(error => console.error(error)),
  update: (data, id) => db().ref(`pods/${id}`).set(data),
  delete: id => {
    db().ref(`pods/${id}`).remove()
  },
}

export const Emails = {
  list: () =>
    db()
      .ref("emails")
      .once("value")
      .then(emails => emails.val())
      .then(emails =>
        emails
          ? Object.entries(emails).map(email => ({ ...email[1], id: email[0] }))
          : []
      )
      .catch(error => console.error(error)),
  fetchEmail: (key, value) =>
    Emails.list()
      .then(emails => emails.find(email => value === email[key]))
      .catch(error => console.error(error)),
  fetchById: id =>
    db()
      .ref("emails")
      .child(id)
      .once("value")
      .then(email => {
        if (!email.val()) throw new Error("No email found")
        return { ...email.val(), id: email.key }
      })
      .catch(error => console.error(error)),
  create: data =>
    db()
      .ref("emails")
      .push(data)
      .then(emailId => emailId)
      .catch(error => console.error(error)),
  update: (data, id) => db().ref(`emails/${id}`).set(data),
  delete: id => {
    db().ref(`emails/${id}`).remove()
  },
}
