import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { ReactQueryDevtools } from "react-query-devtools"

// Style
import "../../assets/stylesheets/critical.scss"

// Blocks
import { Header, SEO, Footer } from "."

// Favicon
import favicon from "../../assets/images/favicon.svg"

const Layout = ({ children }) => {
  return (
    <>
      <SEO />
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      <div className="site-wrapper">
        <Header />
        <main tabIndex="-1" id="main-content">
          {children}
        </main>
        <Footer />
      </div>
      {process.env.NODE_ENV === "development" && <ReactQueryDevtools />}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
