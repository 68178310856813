import "firebase/app"
import "firebase/auth"
import "firebase/database"

import React from "react"
import { AuthContextProvider } from "./src/context/AuthContext"
import CustomLayout from "./wrapPageElement"

// eslint-disable-next-line react/display-name
export const wrapRootElement = ({ element }) => (
  <AuthContextProvider>{element}</AuthContextProvider>
)

export const wrapPageElement = CustomLayout
