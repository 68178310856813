import React from "react"
import { Link } from "gatsby"

// Blocks
import Whale from "./Whale"
import IconFacebook from "../icons/IconFacebook"
import IconTwitter from "../icons/IconTwitter"
import IconLinkedIn from "../icons/IconLinkedIn"

const Footer = () => {
  return (
    <footer className="[ site-footer ]">
      <div className="[ wrapper flow ] ">
        <div className="[ flex flow-hrz ] [ middle ]">
          <div className="logo">
            <Whale />
          </div>
          <ul className="[ flex flow-hrz ] [ social-media ]">
            <li>
              <a href="https://www.facebook.com/podplease/">
                <IconFacebook />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/PodPlease">
                <IconTwitter />
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/podplease/">
                <IconLinkedIn />
              </a>
            </li>
          </ul>
        </div>
        <div>
          <ul className="[ flex flow-hrz ] [ links middle ]">
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/toc">Terms of Use</Link>
            </li>
            <li>
              <Link to="/scamguidelines">Scam Guidelines</Link>
            </li>
            <li>
              <Link to="/safetyguidelines">Safety Guidelines</Link>
            </li>
          </ul>
        </div>
        <p className="[ text-center ]">
          Say Hello 👋:{" "}
          <a href="mailto:Hello@podplease.com">Hello@podplease.com</a>
        </p>
      </div>
    </footer>
  )
}

export default Footer
